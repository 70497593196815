import { FormControl, Validators } from '@angular/forms';
import { CompareErrorStateMatcher } from '@portal/ui-kit/core';
import { wenEqualsValidator } from '@portal/wen-components';

export const PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME = 'passwordRepeat';
export const PASSWORD_COMPARE_ERROR_KEY = 'passwordCompare';

export const createPasswordControl = () => {
  return new FormControl('', [
    Validators.required,
    Validators.minLength(5)
  ]);
};

export const createPasswordErrorStateMatcher = (passwordControlName: string) => {
  return new CompareErrorStateMatcher(passwordControlName, PASSWORD_COMPARE_ERROR_KEY);
};

export const createPasswordsEqualsValidator = (passwordControlName: string) => {
  return wenEqualsValidator(passwordControlName, PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME, PASSWORD_COMPARE_ERROR_KEY, false);
};
