
<ng-container *ngIf="groupedCommentList$ | async as groupedComments; else noComments">
  <ng-container *ngFor="let group of groupedComments">
    <div *messageSeparator="group.dateSeparator"></div>
    <wen-comment #commentElement [userNavigable]="item.comment.userId" *ngFor="let item of group?.comments" [comment]="item.comment" [wenIsCurrentUser]="item.comment.userId"
    (press)="onEmojiReactionClicked(item.comment)" (clickOnQuote)="scrollToQuoteOrigin($event)" (swipeGesture)="onSwipeQuoted($event)" [wen-test-id]="'wen-comment'">
      <wen-emoji-reactions
        [referenceId]="item.comment.id"
        comment-extension
        [wenIsCurrentUser]="item.comment.userId"
        [reactionContext]="ReactionContext.COMMENT"
        (addReaction)="onAddReactionClicked(item.comment)"
      ></wen-emoji-reactions>
    </wen-comment>
  </ng-container>
  <div class="wen-load-more-button" *ngIf="hasMoreToLoad$ | async" (click)="loadMoreComments()" portal-body-1>{{ 'LOAD_MORE' | translate }}</div>
</ng-container>

<ng-template #noComments>
  <div *messageSeparator="emptyCommentListSeparatorContext"></div>
</ng-template>
