import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../core/store/root/public-api';
import { navigateToUserProfile } from '../../../../core/store/user/user.actions';
import { BaseMessageModel } from '../models/base-message-box.model';
import { MessageBoxActionsHandler } from './message-box-tokens';

@Injectable()
export class DefaultMessageBoxActionsHandler extends MessageBoxActionsHandler {

  private store = inject(Store<RootState>);

  handleCommentsClicked(): void {
    throw new Error('DefaultActionsHandler does not support handleCommentsClicked!');
  }

  handleDocumentClicked(): void {
    throw new Error('DefaultActionsHandler does not support handleDocumentClicked!');
  }

  handleForwardClicked(messageId: string): void {
    throw new Error('DefaultActionsHandler does not support handleForwardClicked!');
  }

  handleShareClicked(messageId: string): void {
    throw new Error('DefaultActionsHandler does not support handleShareClicked!');
  }

  handleSenderClicked(messageId: string, authorId: string): void {
    this.store.dispatch(navigateToUserProfile({ userId: authorId }));
  }

  handleChallengeClicked(messageId: string) {
    throw new Error('DefaultActionsHandler does not support handleChallengeClicked!');
  }

  handleReactionClicked(message: BaseMessageModel) {
    throw new Error('DefaultActionsHandler does not support handleReactionClicked!');
  }

  handleMessageContentClicked(message: BaseMessageModel) {
    throw new Error('DefaultActionsHandler does not support handleMessageContentClicked!');
  }
}
