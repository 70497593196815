<wen-we-feed (messageVisible)="onMessageVisible($event)" (scrolledToBottom)="enableAutoAcknowledge()" [flowDirection]="flowDirection"
  [reactionContext]="reactionContext">
  <ng-container ngProjectAs="[wen-messages-view-before-content]">
    <ng-container *ngTemplateOutlet="projectedBeforeContent"
      ngProjectAs="[wen-messages-view-before-content]"></ng-container>
  </ng-container>
  <ng-container ngProjectAs="wen-we-feed-empty-placeholder">
    <ng-container *ngTemplateOutlet="emptyPlaceholder" ngProjectAs="wen-we-feed-empty-placeholder"></ng-container>
  </ng-container>
  <wen-scroll-to-button *ngIf="!disableScrollToBottomButton" [notificationCount]="notificationCount$ | async"
    (scrolledTo)="onScrollToBottomClicked()">
  </wen-scroll-to-button>
</wen-we-feed>

<ng-template #projectedBeforeContent>
  <ng-content select="wen-messages-view-before-content, [wen-messages-view-before-content]"></ng-content>
</ng-template>

<ng-template #emptyPlaceholder>
  <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  <wen-we-feed-empty-placeholder *ngIf="!customEmptyPlaceholder" [text]="'CHANNEL_VIEW_NO_MESSAGE_TITLE' | translate"
    [imageUrl]="'/assets/wen-widget/image/pic-success-120.svg'"></wen-we-feed-empty-placeholder>
</ng-template>
