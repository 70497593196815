import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { ClipboardService } from '../../../../core/services/util/clipboard.service';
import { isForwardEmbed } from '../../../../core/store/channel/util/embed-type-helper';
import { MessageBoxModel } from '../models/message-box.models';

@Injectable()
export class MessageBoxCopyAction implements ActionMenuItem {

  label: string;
  icon = 'copy';

  constructor(
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private wenSnackbarOpener: WenSnackbarOpener
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_COPY');
  }

  isVisible$ = (data: MessageBoxModel) => {
    const text = this.getMessageText(data);
    return of(Boolean(text));
  };

  execute(data: MessageBoxModel) {
    const text = this.getMessageText(data);
    const isSuccess = this.clipboardService.copy(text, true);
    if (isSuccess) {
      this.showSnackbarMessage();
    }
  }

  private showSnackbarMessage() {
    const notificationText = this.translateService.instant('SNACKBAR_LABEL_TEXT_COPY_SUCCESS');
    const notificationIcon = 'copy';
    this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
  }

  private getMessageText(data: MessageBoxModel): string {
    const messageContent = data?.content;
    if (messageContent) {
      return messageContent;
    }
    return data?.embeds?.map(embed => {
      if (isForwardEmbed(embed)) {
        const forwardedMessage = embed.forwardData.data;
        return forwardedMessage?.content;
      }
      return null;
    })[0];
  }
}
