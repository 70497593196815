<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_CONTENT_PROVIDER_LABEL' | translate }}</div>
    <div class="wen-channel-content-provider-list-add-content-provider-button" *ngIf="canAddContentProviders$ | async"  portal-body-2 wen-primary-color (click)="navigateToAddContentProvider()">
      <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
      {{ "CHANNEL_ADD_CONTENT_PROVIDER_TITLE" | translate }}
    </div>
    <div class="wen-form-field-scroller">
      <wen-user-list-item *ngFor="let listItem of userList$ | async" [user]="listItem.user" [userNavigable]="listItem.user.userId">
        <wen-icon *ngIf="listItem.hasMenu" [iconName]="'more'" wen-user-after (click)="openContextMenu(listItem.user.userId)" [color]="contextMenuOpenerFillColor"></wen-icon>
      </wen-user-list-item>
    </div>
    <div class="wen-form-group-hint" portal-subheading-2>{{ 'CHANNEL_CONTENT_PROVIDER_HINT_LABEL' | translate }}</div>
  </div>
</div>