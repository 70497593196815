<div class="wen-password-field-wrapper">
  <mat-form-field>
    <mat-label>{{ labels.passwordLabel }}</mat-label>
    <input #passwordInput [formControlName]="forFormControlName" matInput autocomplete="new-password" type="password"
      wenIosKeyboardStickyTarget />
    <mat-error *ngIf="showGenericPasswordError()" portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_ERROR' |
      translate }}</mat-error>
    <mat-error *ngIf="showMinLengthPasswordError()" portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_LENGTH_ERROR'
      | translate: { length: 5 } }}</mat-error>
    <wen-password-visibility-toggle *ngIf="passwordValue" matSuffix [for]="passwordInput">
    </wen-password-visibility-toggle>
  </mat-form-field>
</div>

<div class="wen-password-field-repeat-wrapper" *ngIf="withRepeatValue && passwordRepeatControl">
  <mat-form-field>
    <mat-label>{{ labels.passwordRepeatLabel }}</mat-label>
    <input #passwordRepeatInput [formControlName]="PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME"
      [errorStateMatcher]="comparePasswordErrorMatcher" matInput autocomplete="new-password" type="password"
      wenIosKeyboardStickyTarget />
    <mat-error portal-caption>{{ labels.passwordRepeatError }}</mat-error>
    <wen-password-visibility-toggle *ngIf="passwordRepeatValue" matSuffix [for]="passwordRepeatInput">
    </wen-password-visibility-toggle>
  </mat-form-field>
</div>