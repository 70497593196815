import { inject, Injectable } from '@angular/core';
import { OlmEncryptedMessage } from '@portal/wen-backend-api';
import { first, forkJoin, map, Observable, switchMap } from 'rxjs';
import { OlmDevice } from '../device/olm-device';
import { OlmDecryptionError, OlmInboundDesyncError } from '../error-types';
import { CryptoStorage } from '../persistence/crypto-storage';
import { getCurrentDateTime } from '../util/date-util';
import { OlmDecryptionResult } from './crypto-results';

@Injectable()
export class OlmDecryptor {

  private olmDevice = inject(OlmDevice);
  private dataStore = inject(CryptoStorage);

  private tryDecryptMessage(
    senderCurve25519: string,
    session: string,
    sessionId: string,
    message: OlmEncryptedMessage,
    lastActivityTimestamp: number
  ) {
    try {
      const decrypted = this.olmDevice.decryptMessage(session, message);
      const storeAcc$ = this.dataStore.storeAccount(this.olmDevice.getAccountModel());
      const storeSession$ = this.dataStore.storeSession({ session, sessionId, curve25519: senderCurve25519, lastActivityTimestamp });
      return forkJoin([storeAcc$, storeSession$]).pipe(
        map(() => {
          return {
            ...decrypted,
            senderCurve25519
          };
        })
      );
    } catch {
      if (this.olmDevice.matchesSession(session, message.body)) {
        throw new OlmInboundDesyncError({ sessionId, senderCurve25519 });
      }
    }
    return null;
  }

  decryptMessage(userId: string, senderCurve25519: string, message: OlmEncryptedMessage): Observable<OlmDecryptionResult> {
    const session$ = this.dataStore.getSessionsForDevice({ curve25519: senderCurve25519 });
    return session$.pipe(
      first(),
      switchMap((sessionModels) => {
        for (const sessionModel of sessionModels) {
          const { session, sessionId, lastActivityTimestamp } = sessionModel;
          try {
            const storedDecryptionResult = this.tryDecryptMessage(senderCurve25519, session, sessionId, message, lastActivityTimestamp);
            if (storedDecryptionResult) {
              return storedDecryptionResult;
            }
          } catch {
            if (this.olmDevice.matchesSession(sessionModel.session, message.body)) {
              throw new OlmInboundDesyncError({ sessionId, senderCurve25519 });
            }
          }
        }
        const { session: newSession, sessionId: newSessionId } = this.olmDevice.createInboundSession(senderCurve25519, message);
        const result = this.tryDecryptMessage(
          senderCurve25519, newSession, newSessionId, message, getCurrentDateTime()
        );
        if (result) {
          return result;
        }
        throw new OlmDecryptionError({ sessionId: newSessionId, senderCurve25519 });
      })
    );
  }

}
