import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { equalsValidator } from '@portal/ui-kit/core';

export const validateEmail = (maybeEmail: string): boolean => {
  return maybeEmail.includes('@');
};

export const emailValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    if (!validateEmail(value)) {
      return { emailFormat: true };
    }
    return null;
  };
};

export const webURLValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (!value || typeof value !== 'string') {
      return null;
    }
    if (!/^https?:\/\/.*/.test(value)) {
      return { invalidUrl: true };
    }
    return null;
  };
};

export const nativeUriValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (!value || typeof value !== 'string') {
      return null;
    }
    if (!(/^(|.+:\/\/.+)$/).test(value)) {
      return { invalidNativeUri: true };
    }
    return null;
  };
};

export const wenEqualsValidator = (
  control1: string, control2: string, errorKey: string, ignoreDisabled: boolean
): (formGroup: FormGroup) => { [x: string]: boolean } => {
  const equalsValidatorFn = equalsValidator(control1, control2, errorKey);
  if (!ignoreDisabled) {
    return equalsValidatorFn;
  }
  return (formGroup: FormGroup) => {
    const field1 = formGroup.get(control1);
    const field2 = formGroup.get(control2);
    if (field1.disabled && field2.disabled) {
      return {};
    }
    return equalsValidatorFn(formGroup);
  };
};

export function updateableValidator(errorKey: string) {
  let isValid = true;
  return {
    setValid: () => isValid = true,
    setInValid: () => isValid = false,
    validator: (): ValidationErrors | null => {
      return isValid ? null : { [errorKey]: true };
    }
  };
}
