import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ForwardContent, ForwardContext, MessageModificationState } from '@portal/wen-backend-api';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { ForwardService } from '../../../../../core/services/forward/forward.service';
import { isForwardEmbed, isMediaEmbed } from '../../../../../core/store/channel/util/embed-type-helper';
import { MessageBoxModel } from '../../models/message-box.models';

@Injectable()
export class ChatMessageBoxForwardAction implements ActionMenuItem {

  label: string;
  icon = 'forward';

  constructor(
    private translateService: TranslateService,
    private forwardService: ForwardService,
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_FORWARD');
  }

  isVisible$ = (data: MessageBoxModel) => {
    const isProperMessage = data.state === MessageModificationState.ORIGINAL || data.state === MessageModificationState.EDITED;
    const hasNotUploadedMedia = data.embeds.filter(isMediaEmbed).some(embed => !embed.uploaded);
    return of(isProperMessage && !data.scheduled && !data.isDraft && !hasNotUploadedMedia);
  };

  execute(data: MessageBoxModel) {
    const message = this.getForwardableMessage(data);
    this.forwardService.initiateForward(
      message,
      ForwardContext.CHAT_MESSAGE,
      data.referenceId
    );
  }

  private getForwardableMessage(data: MessageBoxModel): ForwardContent {
    const originalMessage = data;
    const validMessage = originalMessage?.content || (originalMessage.embeds.filter(embed => !isForwardEmbed(embed)).length > 0);
    if (validMessage) {
      return originalMessage;
    }
    return data?.embeds?.map(embed => {
      if (isForwardEmbed(embed)) {
        const forwardedMessage = embed.forwardData.data;
        return forwardedMessage;
      }
      return null;
    })[0];
  }
}
