import { AnimationEvent } from '@angular/animations';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { WenTestIdModule } from '../../../directives/test-id/test-id.module';
import { contextMenuAnimations } from '../context-menu-animations';
import { CONTEXT_MENU_DATA } from '../context-menu-data';

@Component({
  selector: 'wen-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PortalModule,
    WenTestIdModule
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contextMenuAnimations.slideInOut]
})
export class ContextMenuComponent implements OnInit {

  private contextMenuData = inject(CONTEXT_MENU_DATA, { optional: true });
  private renderer = inject(Renderer2);
  private elRef = inject(ElementRef<ContextMenuComponent>);
  animationStateChanged = new EventEmitter<AnimationEvent>();
  portal: ComponentPortal<any>;

  @HostBinding('class.wen-context-menu') className = true;
  @HostBinding('@slideInOut') animate = true;

  @HostListener('@slideInOut.start', ['$event']) start(event: AnimationEvent) {
    this.onAnimationStart(event);
  }

  @HostListener('@slideInOut.done', ['$event']) done(event: AnimationEvent) {
    this.onAnimationEnd(event);
  }

  ngOnInit() {
    if (this.contextMenuData?.component) {
      this.portal = new ComponentPortal(this.contextMenuData.component);
    }
    if (this.contextMenuData?.style) {
      const style = this.contextMenuData.style;
      this.renderer.setStyle(this.elRef.nativeElement, style.styleName, style.styleValue);
    }
  }

  private onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.next(event);
  }

  private onAnimationEnd(event: AnimationEvent) {
    this.animationStateChanged.next(event);
  }

}
