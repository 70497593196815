import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, distinctUntilChanged, map, switchMap } from 'rxjs';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { ChatMediaPreviewHandler } from '../../../../../core/services/util/chat-media-preview-handler';
import { MediaPreviewHandler } from '../../../../../core/services/util/media-preview-handler';
import { selectDraftChatMessageById } from '../../../../../core/store/chat/chat.selectors';
import { requestShareInboundGroupSessionForCurrentRoom } from '../../../../../core/store/chat/key-actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../../core/store/root/root.selectors';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { FutureMessageSeparatorDateFormatter, MessageSeparatorDateFormatter } from '../../../../../shared/directives/directives/message-separator-formatters';
import { ChatMessageSendFieldDataSource } from '../../../chat-view/providers/chat-message-send-field-data-source';
import { chatViewIdentifier } from '../../../tokens';
import { ChatScheduledMessagesFeedDatasource } from '../../providers/chat-scheduled-messages-feed.datasource';
import { FileDropOverlayComponent } from '../../../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { FileEventHandlerService } from '../../../../../shared/services/file-event-handler.service';
import { ChatMessageCustomizations } from '../../../common/components/chat-messages-view/providers/chat-message-customizations';
import { MessageBoxCustomizations } from '../../../../../shared/components/message-box/providers/message-box-embed-customizations';

@Component({
  selector: 'wen-chat-schedules-view',
  templateUrl: './chat-schedules-view.component.html',
  styleUrls: ['./chat-schedules-view.component.scss'],
  providers: [
    {
      provide: FeedDatasource,
      useClass: ChatScheduledMessagesFeedDatasource
    },
    FeedLayoutMediator,
    {
      provide: MessageSeparatorDateFormatter,
      useClass: FutureMessageSeparatorDateFormatter
    },
    { provide: MessageSendFieldDataSource, useClass: ChatMessageSendFieldDataSource },
    { provide: MediaPreviewHandler, useClass: ChatMediaPreviewHandler },
    { provide: MessageBoxCustomizations, useClass: ChatMessageCustomizations }
  ]
})
export class ChatSchedulesViewComponent implements OnInit, OnDestroy {
  overlayContent = FileDropOverlayComponent;
  isMessageInputVisible$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private messageSendFieldDataSource: MessageSendFieldDataSource,
    private readonly fileEventHandler: FileEventHandlerService,
  ) { }

  ngOnInit() {
    this.isMessageInputVisible$ = this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      switchMap((roomId) => this.store.pipe(
        selectorWithParam(selectDraftChatMessageById, roomId),
      )),
      map(message => Boolean(message?.id) && Boolean(message?.scheduled)),
      distinctUntilChanged(),
    );
  }

  onFocusSendField() {
    this.store.dispatch(requestShareInboundGroupSessionForCurrentRoom());
  }

  ngOnDestroy() {
    this.messageSendFieldDataSource.discardDraftMessage();
  }

  onFilesDropped(files: FileList) {
    this.fileEventHandler.addFiles(files);
  }

}
