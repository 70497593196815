<wen-list-item *ngIf="channel" [showAfter]="showAfterElement" #wenListItem [selected]="isSelected$ | async"
  wen-test-id="wen-channel-list-item" (press)="enableContextMenu && openContextMenu(channel)">
  <wen-circle-image-view wen-list-item-icon [imageUrl]="channel.imageUrl" [placeholderText]="channel.title">
    <wen-channel-indicator *ngIf="channel.channelIndicator" [indicatorType]="channel.channelIndicator"
                           wen-circle-image-overlay positionOffset="39px" rightOffset="18px"></wen-channel-indicator>
  </wen-circle-image-view>
  <wen-icon class="wen-icon-small" wen-list-item-title-prefixicon *ngIf="channel.lockIcon; let lockIcon"
    [iconName]="lockIcon" [color]="'#767676'"></wen-icon>
  <span wen-list-item-title [wen-test-id]="'wen-channel-list-item-title'">{{ channel.title }}</span>
  <wen-icon *ngIf="channel.muteIcon; let muteIcon" wen-list-item-title-suffixicon
    class="wen-channel-list-item-title-icon wen-icon-small" [iconName]="muteIcon" [color]="'#767676'"></wen-icon>
  <wen-icon *ngIf="channel.pinTimestamp" wen-list-item-title-suffixicon
    class="wen-channel-list-item-title-icon wen-icon-small" iconName="pin" color="#767676"
    [wen-test-id]="'wen-channel-list-item-pin-icon'"></wen-icon>
  <div wen-list-item-description *ngIf="channel?.description as description"
    [wen-test-id]="'wen-channel-list-item-description'">
    <wen-icon *ngIf="description?.icon" [iconName]="description?.icon" class="wen-icon-small"></wen-icon>
    <span class="wen-channel-list-item-description" *ngIf="description?.text"
      [innerHtml]="description.text | translate | removeMarkdown : markdownEnabled"></span>
  </div>
  <span wen-list-item-subtitle *ngIf="channel | wenHasTrueProperty: 'subscribed'"
    [wen-test-id]="'subscribed-channel-subtitle'">
    <wen-icon class="wen-icon-tiny" iconName="save_active"></wen-icon>
    {{ 'CHANNEL_LIST_SUBSCRIBED' | translate }}
  </span>
  <span wen-list-item-subtitle *ngIf="channel | wenHasTrueProperty: 'pendingJoinRequest'">
    <wen-icon class="wen-icon-tiny" [iconName]="'help'"></wen-icon>
    {{ 'JOIN_REQUEST_SENT_DISCOVERY_STATUS_LABEL' | translate }}
  </span>

  <ng-container wen-list-item-after>
    <ng-container *ngIf="channel.timestamp">
      <div portal-subheading-1 class="wen-channel-list-item-timestamp">{{ channel.timestamp | messageDateFormatter }}
      </div>
    </ng-container>
    <ng-container *ngIf="channel.notifications; let notification">
      <wen-notification-count *ngIf="notification.count > 0; else badgeNotification;" class="wen-notification-count-big"
        [value]="notification.count" [disabled]="notification.isMuted"
        [wen-test-id]="'channel-list-notification-count'"></wen-notification-count>
      <ng-template #badgeNotification>
        <wen-notification-count *ngIf="notification.badgeStyle" class="wen-notification-count-big"
          [value]="'INVITE_CHANNEL_NOTIFICATION_COUNT_LABEL' | translate"></wen-notification-count>
      </ng-template>
    </ng-container>
  </ng-container>
</wen-list-item>
