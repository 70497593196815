import { Injectable } from '@angular/core';
import { EmbeddedMedia } from '../../../../../../core/store/channel/channel.state';
import { MessageBoxModel } from '../../../../../../shared/components/message-box/models/message-box.models';
import { MessageBoxCustomizations } from '../../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { MessageModificationState } from '@portal/wen-backend-api';

@Injectable()
export class ChatMessageCustomizations extends MessageBoxCustomizations {
  handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: MessageBoxModel): EmbeddedMedia[] {
    return super.noopMediaFilter(mediaEmbeds);
  }
  handleMessageValidation(message?: MessageBoxModel): boolean {
    return message?.state !== MessageModificationState.DELETED && message?.state !== MessageModificationState.ERROR;
  }
  canOpenContextMenu(message?: any): boolean {
    return message.state !== MessageModificationState.DELETED;
  }
}
