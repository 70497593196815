import { inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { map, Observable } from 'rxjs';
import { UserChangePasswordChangedValuesStep1 } from '../../../../../core/store/form/types/user-change-password-types';
import { FormAsyncValidator } from '../../../../../shared/form-store/form-store.providers';

@Injectable()
export class UserChangePasswordDatasource implements FormAsyncValidator<UserChangePasswordChangedValuesStep1> {

  private socketIoService = inject(SocketIoService);
  private snackbarOpener = inject(WenSnackbarOpener);
  private translateService = inject(TranslateService);

  validate(control: AbstractControl<UserChangePasswordChangedValuesStep1>): Observable<ValidationErrors> {
    const { newPassword, oldPassword } = control.value;
    return this.socketIoService.user.requestPasswordChange.acknowledgement$({
      oldPassword: btoa(oldPassword),
      newPassword: btoa(newPassword)
    }).pipe(
      map((result) => {
        if (result.violations?.length > 0) {
          this.snackbarOpener.openNotificationSnackbar({
            notificationIcon: 'error',
            notificationText: this.translateService.instant('USER_PROFILE_FORM_CHANGE_PASSWORD_OLD_PASSWORD_MISMATCH_ERROR')
          });
          const errors = result.violations.reduce((acc, violation) => {
            return { ...acc, [violation.id]: true };
          }, {});
          return errors;
        }
        return null;
      })
    );
  }

}
