import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RoomMessageDTO, generateId, EditRelation, QuoteRelation, RelationType, EmbeddedQuoteDTO } from '@portal/wen-backend-api';
import { ChatMessageTransactionData } from '@portal/wen-chat-client';
import { WenSnackbarOpener } from '@portal/wen-components';
import { combineLatest, first, map, merge, mergeAll, mergeMap, of, race, switchMap, tap } from 'rxjs';
import { nonNullArray } from '../../../common/operators/array-utils';
import { createServerTimeout } from '../../../common/util/create-timer';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { ChatAvailabilityCheckerWithOverlay } from '../../../services/chat/chat-availability-checker-with-overlay';
import { EncryptedChatMessageSender } from '../../../services/chat/message-sender/encrypted-chat-message-sender';
import { MessageLoaderStrategyFactory } from '../../../services/chat/message-sender/use-cases/message-loader';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { DraftMessageEntity } from '../../common/models';
import { RootState } from '../../root/public-api';
import { continueCRMChatFlow } from '../../smartdesign/smartdesign.actions';
import { selectIsFromSmartDesign } from '../../smartdesign/smartdesign.selectors';
import { actualizeDraftChatRoom, actualizeGlobalDraftChat, cleanUpDraftChat, initFileEmbedCacheForRoom, navigateToRoom, removeDraftChatMessage, showScheduledChatPostSuccessToast } from '../chat.actions';
import { selectAllDraftChatMemberIds, selectAllDraftChatRoomIds, selectCurrentRoom, selectDraftChatMessage, selectDraftChatMessageById } from '../chat.selectors';

@Injectable()
export class MessageSendEffects {

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  actualizeGlobalDraftChat$ = createEffect(() => this.actions$.pipe(
    ofType(actualizeGlobalDraftChat),
    mergeMap(() => {
      return combineLatest([
        this.store.pipe(select(selectAllDraftChatRoomIds)),
        this.store.pipe(select(selectDraftChatMessage)),
        this.store.pipe(select(selectAllDraftChatMemberIds)),
      ]).pipe(
        first(),
        switchMap(([roomIds, message, userIds]) => {
          const dialogData = { errorMessageLabel: 'ERROR_ROOM_CANNOT_BE_CREATED' };
          const dialogTitle = 'ERROR_GENERIC_DIALOG_MESSAGE';
          return this.chatAvailabilityChecker.checkChatAvailabilityWithUsersWithErrorDialog(userIds, dialogData, dialogTitle).pipe(
            map((unavailableUserIds) => {
              const availableUserIds = userIds.filter(userId => !unavailableUserIds.includes(userId));
              return { userIds: availableUserIds, roomIds, message };
            })
          );
        })
      );
    }),
    mergeMap(({ message, userIds, roomIds }) => {
      return this.broadcastDraftMessage(message, userIds, roomIds);
    })
  ));

  actualizeDraftChatRoom$ = createEffect(() => this.actions$.pipe(
    ofType(actualizeDraftChatRoom),
    mergeMap(({ roomId }) => {
      return this.store.pipe(selectorWithParam(selectDraftChatMessageById, roomId)).pipe(
        first(),
        map((message) => ({ roomIds: [roomId], message }))
      );
    }),
    mergeMap(({ message, roomIds }) => {
      return this.broadcastDraftMessage(message, [], roomIds);
    })
  ));

  createNotifySuccessfulScheduleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(showScheduledChatPostSuccessToast),
    tap(() => {
      this.wenSnackBar.openNotificationSnackbar({
        notificationIcon: 'success',
        notificationText: this.translateService.instant(
          'SCHEDULED_MESSAGES_CREATED_NOTIFICATION'
        )
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
    private chatAvailabilityChecker: ChatAvailabilityCheckerWithOverlay,
    private chatMessageSender: EncryptedChatMessageSender,
    private messageLoaderStrategyFactory: MessageLoaderStrategyFactory,
    private wenSnackBar: WenSnackbarOpener,
    private translateService: TranslateService
  ) { }

  private broadcastDraftMessage(message: DraftMessageEntity, dialogUserIds: string[], roomIds: string[]) {
    if (!dialogUserIds.length && !roomIds.length) {
      return [cleanUpDraftChat()];
    }
    const { id: originalEventId, content, fileEmbed, forwardEmbed, timestamp, scheduled, quote } = message;
    const scheduledFor = scheduled && timestamp;
    const convertDraftToMessage = (): RoomMessageDTO => {
      const embeds = nonNullArray([fileEmbed, forwardEmbed]);
      const backwardCompatibleMessageWithId: RoomMessageDTO = {
        id: generateId(),
        content,
        embeds,
      };
      return backwardCompatibleMessageWithId;
    };
    const tryAttachRelationsToRoomData = (roomData: ChatMessageTransactionData) => {
      if (originalEventId) {
        const editRelation: EditRelation = {
          replace: {
            eventId: originalEventId
          },
          type: RelationType.EDIT
        };
        roomData.relation = editRelation;
      }
      if (quote) {
        const quoteRelation: QuoteRelation = {
          quote: {
            eventId: (quote as EmbeddedQuoteDTO).quotedItemId
          },
          type: RelationType.QUOTE
        };
        roomData.relation = quoteRelation;
      }
      return roomData;
    };
    const dialogRoomDatas = dialogUserIds.map(userId => {
      const dialogData: ChatMessageTransactionData = {
        dialogUserId: userId,
        roomMessage: {
          message: convertDraftToMessage()
        },
        scheduledFor
      };
      return dialogData;
    });
    const roomDatas = roomIds.map(roomId => {
      let roomData: ChatMessageTransactionData = {
        roomId,
        roomMessage: {
          message: convertDraftToMessage()
        },
        scheduledFor,
        originalEventId
      };
      roomData = tryAttachRelationsToRoomData(roomData);
      return roomData;
    });
    const transaction = this.chatMessageSender.sendMessages(
      this.messageLoaderStrategyFactory.createForDirectSending([...dialogRoomDatas, ...roomDatas])
    );
    transaction.execute();
    const sendMessageResult$ = transaction.allMessageSendFinished$;
    const singleMessageSendFinished$ = transaction.singleMessageSendFinished$;
    const beforeSaveActions: Action[] = [];
    beforeSaveActions.push(cleanUpDraftChat());
    const saveTimeout$ = createServerTimeout();
    const beforeSave$ = of(beforeSaveActions).pipe(
      mergeAll()
    );
    const save$ = merge(
      race(sendMessageResult$, saveTimeout$)
    );
    const afterAllSaved$ = save$.pipe(
      switchMap((result) => this.store.pipe(
        select(selectIsFromSmartDesign),
        first(),
        switchMap((isFromSmartDesign) => {
          if (!result) {
            return [];
          }
          const afterSaveActions: Action[] = [];
          if (isFromSmartDesign) {
            afterSaveActions.push(continueCRMChatFlow());
          }
          if (scheduledFor) {
            afterSaveActions.push(showScheduledChatPostSuccessToast());
          }
          return afterSaveActions;
        })
      )),
    );
    const initEmbedCachesForMessages$ = singleMessageSendFinished$.pipe(
      switchMap((singlResult) => {
        const roomId = singlResult.roomMemberData.roomId;
        const embeds = transaction.getUploadableMediaEmbeds();
        return embeds.map(({ file, uploadId }) => {
          return initFileEmbedCacheForRoom({ roomId, uploadId, blobUrl: URL.createObjectURL(file) });
        });
      })
    );
    const cleanDrafts$ = singleMessageSendFinished$.pipe(
      map((singlResult) => {
        const roomId = singlResult.roomMemberData.roomId;
        return removeDraftChatMessage({ roomId });
      })
    );
    const currentRoute$ = combineLatest([
      this.store.pipe(select(selectIsFromSmartDesign)),
      this.store.pipe(select(selectCurrentRoom), map(Boolean))
    ]);
    const navigateToFirstChat$ = singleMessageSendFinished$.pipe(
      first(),
      switchMap((singlResult) => currentRoute$.pipe(
        first(),
        switchMap(([isFromSmartDesign, isInChatView]) => {
          if (!singlResult || isFromSmartDesign || isInChatView) {
            return [];
          }
          const roomId = singlResult.roomMemberData.roomId;
          return [navigateToRoom({ roomId, fromCreateMode: true })];
        })
      )),
    );
    return merge(
      beforeSave$,
      initEmbedCachesForMessages$,
      save$.pipe(switchMap(() => [])),
      afterAllSaved$,
      cleanDrafts$,
      navigateToFirstChat$
    );
  }

}
