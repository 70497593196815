<wen-adaptive-background [backgroundStyle]="'flat'" [desktopWidth]="470">
  <div wen-adaptive-background-content class="wen-onboarding-wrapper" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
    <ng-lottie containerClass="wen-onboarding-lottie-player" [options]="options" [styles]="styles"
      (animationCreated)="animationCreated($event)" (domLoaded)="domLoaded()" (enterFrame)="enterFrame($event)">
    </ng-lottie>
    <div @controlsEnter class="wen-onboarding-controls" *ngIf="controlsVisible">
      <div class="wen-onboarding-controls-text">
        <div @fadeInOut class="wen-onboarding-controls-logo-curtain" *ngIf="currentSegment > 1"></div>
        <div @fadeInOut class="wen-onboarding-controls-text-content" *ngIf="currentSegment === 1">
          <ng-container *ngTemplateOutlet="startText"></ng-container>
        </div>
        <div @fadeInOut class="wen-onboarding-controls-text-content" *ngIf="currentSegment === 2">
          <ng-container *ngTemplateOutlet="fairDigitalText"></ng-container>
        </div>
        <div @fadeInOut class="wen-onboarding-controls-text-content" *ngIf="currentSegment === 3">
          <ng-container *ngTemplateOutlet="channelsText"></ng-container>
        </div>
        <div @fadeInOut class="wen-onboarding-controls-text-content" *ngIf="currentSegment === 4">
          <ng-container *ngTemplateOutlet="addinsText"></ng-container>
        </div>
      </div>
      <div class="wen-onboarding-controls-skip-wrapper">
        <wen-rounded-button transparent *ngIf="controlsVisible" (click)="goToWenAuthPage()">{{
          'ONBOARDING_SKIP' | translate }}</wen-rounded-button>
      </div>
      <div class="wen-onboarding-controls-buttons-wrapper">
        <div class="wen-onboarding-controls-buttons">
          <wen-rounded-button [style.visibility]="canGoBack ? 'visible' : 'hidden'" background (click)="playSegment(-1)">
            {{ 'ONBOARDING_BACK' | translate }}
          </wen-rounded-button>
          <wen-onboarding-progress [currentProgress]="currentSegment - 1" [segments]="segments.length - 1">
          </wen-onboarding-progress>
          <wen-rounded-button (click)="playSegment(1)">
            {{ (!isLastSegment ? 'ONBOARDING_MORE' : 'ONBOARDING_HERE_WE_GO') | translate }}
          </wen-rounded-button>
        </div>
      </div>
    </div>
  </div>
</wen-adaptive-background>

<ng-template #startText>
  <h5 class="wen-onboarding-start-header" [innerHtml]="'&nbsp'"></h5>
  <div portal-headline [innerHtml]="'ONBOARDING_START_TEXT' | translate"></div>
</ng-template>
<ng-template #fairDigitalText>
  <h5 class="wen-onboarding-fair-digital-header" [innerHtml]="'ONBOARDING_FAIR_DIGITAL_HEADER' | translate"></h5>
  <div portal-headline [innerHtml]="'ONBOARDING_FAIR_DIGITAL_TEXT' | translate"></div>
</ng-template>
<ng-template #channelsText>
  <h5 class="wen-onboarding-channels-header" [innerHtml]="'ONBOARDING_CHANNELS_HEADER' | translate"></h5>
  <div portal-headline [innerHtml]="'ONBOARDING_CHANNELS_TEXT' | translate"></div>
  <div class="wen-onboarding-channels-caption">
    <div portal-caption class="wen-onboarding-channels-disclaimer">
      {{ 'ONBOARDING_CHANNELS_DISCLAIMER' | translate }}
      <a portal-caption (click)="goToPrivacyPolicyPage()">{{'USER_PROFILE_PRIVACY_POLICY' | translate}}</a>.
    </div>
  </div>
</ng-template>
<ng-template #addinsText>
  <h5 class="wen-onboarding-channels-header" [innerHtml]="'ONBOARDING_ADDINS_HEADER' | translate"></h5>
  <div portal-headline [innerHtml]="'ONBOARDING_ADDINS_TEXT' | translate"></div>
</ng-template>
